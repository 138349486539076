<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card>
    <v-card-title class="align-baseline">
      Payments
      <v-switch
          v-model="free"
          class="pa-3"
          label="Free"
      ></v-switch>
      <v-switch
          v-model="confirmed"
          class="pa-3"
          label="Confirmed"
      ></v-switch>
      <v-switch
          v-model="pending"
          class="pa-3"
          label="Pending"
      ></v-switch>
      <div style="max-width: 200px">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                label="Choose a date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div style="max-width: 200px">
        <v-select
            v-model="course"
            :items="courses"
            item-text="title"
            item-value="id"
            label="Select course"
            prepend-icon="mdi-book-open"
            clearable
        ></v-select>
      </div>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <div class="d-flex justify-end mb-2" v-if="yearlyTotal">
      <div class="mx-2">
        Daily: <b>{{ dailyTotal }}</b>,
      </div>
      <div class="mx-2">
        Monthly: <b>{{ monthlyTotal }}</b>,
      </div>
      <div class="mx-2">
        Yearly: <b>{{ yearlyTotal }}</b>
      </div>
    </div>
    <v-card-subtitle v-if="selected.length">
      <v-row>
        <v-col>
          <v-toolbar
              dense
              dark
              rounded
              outlined
              max-width="200"
          >
            <v-btn icon small @click="bulkAccept">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon small @click="bulkDelete">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-data-table
        dense
        :headers="headers"
        :items="payments"
        :options.sync="options"
        :server-items-length="totalPayments"
        :footer-props="footerProps"
        :items-per-page="20"
        :loading="loading"
        item-key="id"
        :search="search"
        class="elevation-1"
        show-select
        v-model="selected"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.updated_at | calendar }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            color="green"
            @click="editItem(item)"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
            color="red"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2"
import moment from "moment";

export default {
  data: () => ({
    payments: [],
    selected: [],
    footerProps: {
      itemsPerPageOptions: [20, 50, 100, 500]
    },
    date: '',
    courses: [],
    course: '',
    menu: false,
    search: '',
    page: 1,
    pageCount: 0,
    totalPayments: 0,
    options: {},
    loading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {text: 'User ID', value: 'user.id'},
      {text: 'Name', value: 'user.name'},
      {text: 'Phone', value: 'phone'},
      {text: 'Course', value: 'course.title'},
      {text: 'Amount', value: 'amount'},
      {text: 'Tran. ID', value: 'transaction_id'},
      {text: 'Coupon', value: 'coupon'},
      {text: 'Note', value: 'note'},
      {text: 'Added By', value: 'added_by'},
      {text: 'Confirmed', value: 'confirmed'},
      {text: 'Time', value: 'created_at'},
      {text: 'Action', value: 'actions', sortable: false}
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedItemForDelete: '',
    free: false,
    confirmed: false,
    pending: false,
    filter: '',
    dailyTotal: '',
    monthlyTotal: '',
    yearlyTotal: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    selectedIds() {
      return this.selected.map(a => a.id)
    },
    filterTerm() {
      if (this.free) {
        return 'filter=free'
      } else if (this.confirmed) {
        return 'filter=confirmed'
      } else if (this.pending) {
        return 'filter=pending'
      } else {
        return 'filter=all'
      }
    },
    sortTerm() {
      return this.options.sortBy[0] ? '&sort=' + this.options.sortBy[0] : ''
    },
    dateTerm() {
      return this.date ? '&date=' + this.date : ''
    },
    courseTerm() {
      return this.course ? '&course_id=' + this.course : ''
    },
    descTerm() {
      return this.options.sortDesc[0] ? '&desc=' + this.options.sortDesc[0] : ''
    }
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
      deep: true,
    },
    filterTerm() {
      this.initialize()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    search() {
      if (this.search.length) {
        this.searchPayment()
      } else {
        this.initialize()
      }
    },
    free() {
      if (this.free) {
        this.confirmed = false
        this.pending = false
      }
    },
    confirmed() {
      if (this.confirmed) {
        this.free = false
        this.pending = false
      }
    },
    pending() {
      if (this.pending) {
        this.confirmed = false
        this.free = false
      }
    },
    date() {
      this.initialize()
    },
    course() {
      this.initialize()
    }
  },
  created() {
    this.loadCourses()
    this.loadReport()
  },
  methods: {
    loadCourses() {
      const url = 'admin/courses?filter=admin'
      axios.get(url).then((response) => {
        this.courses = response.data
      })
    },
    loadReport(){
      const url = 'admin/payment-report?'+this.dateTerm
      axios.get(url).then(response=>{
        if (!response.data){
          return
        }
        this.dailyTotal = response.data.daily_total
        this.monthlyTotal = response.data.monthly_total
        this.yearlyTotal = response.data.yearly_total
      })
    },
    bulkAccept() {
      Swal.fire({
        title: 'Confirmation',
        text: "Do you want to accept these payments?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/payments/action/bulk'
          axios.post(url, {ids: this.selectedIds, type: 'bulk_accept'}).then(() => {
            Swal.fire(
                'Success!',
                'success'
            )
            this.selected = []
            this.initialize()
          })
        }
      })
    },
    bulkDelete() {
      Swal.fire({
        title: 'Confirmation',
        icon: 'warning',
        text: "Do you want to delete these payments?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/payments/action/bulk'
          axios.post(url, {ids: this.selectedIds, type: 'bulk_delete'}).then(() => {
            Swal.fire(
                'Deleted!',
                'success'
            )
            this.selected = []
            this.initialize()
          })
        }
      })
    },
    navigate() {
      this.initialize()
    },
    initialize() {
      this.loading = true
      let link = 'payment?' + this.filterTerm + '&page=' + this.options.page + this.sortTerm + this.descTerm + this.courseTerm + this.dateTerm + '&per_page=' + this.options.itemsPerPage
      axios.get(link).then((response) => {
        this.payments = response.data.data
        this.totalPayments = response.data.total
        this.loading = false
      })
      this.loadReport()
    },
    searchPayment(page = 1) {
      this.loading = true
      let link = 'payment?filter=search&page=' + page + '&query=' + this.search
      axios.get(link).then((response) => {
        this.payments = response.data.data
        this.totalPayments = response.data.total
        this.loading = false
      })
    },
    editItem(item) {
      const url = 'payment/' + item.id;
      let data = {
        confirmed: 1,
        status: 'accepted'
      };
      axios.put(url, data).then(() => {
        this.initialize();
      })
    },
    deleteItem(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedItemForDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const url = 'payment/' + this.selectedItemForDelete.id;
      axios.delete(url).then(() => {
        this.payments.splice(this.editedIndex, 1);
        this.closeDelete();
      }).catch((error) => {
        console.log(error)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.payments[this.editedIndex], this.editedItem)
      } else {
        this.payments.push(this.editedItem)
      }
      this.close()
    },
  },
  filters: {
    calendar(value) {
      return moment(value).format('D-MMM-Y hh:mm a')
    }
  }
}
</script>